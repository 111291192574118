import { template as template_a35211e69b6d4a289426c69293bb2271 } from "@ember/template-compiler";
const FKText = template_a35211e69b6d4a289426c69293bb2271(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
