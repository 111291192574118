import { template as template_c8b68dd44eb04ba09bc740a2cf2c8ab3 } from "@ember/template-compiler";
const FKLabel = template_c8b68dd44eb04ba09bc740a2cf2c8ab3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
